import { AuthPage } from '@components/pages/auth';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function LoginPage() {
  console.log('I am login page');
  return (
    <AuthPage
      type="login"
      providers={[
        {
          name: 'google',
          label: 'Google',
        },
      ]}
    />
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const i18nProps = await serverSideTranslations(context.locale ?? 'en', [
    'common',
  ]);

  return {
    props: {
      ...i18nProps,
    },
  };
};
